import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionComponent = _resolveComponent("SectionComponent")!
  const _component_CalendarComponent = _resolveComponent("CalendarComponent")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_SplitComponent = _resolveComponent("SplitComponent")!
  const _component_ResponsiveImageGridComponent = _resolveComponent("ResponsiveImageGridComponent")!
  const _component_FullWidthBannerComponent = _resolveComponent("FullWidthBannerComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionComponent, {
      title: 'The LoLA Creative Space',
      backgroundImage: '/img/images/about_page_02.png'
    }),
    _createVNode(_component_CalendarComponent),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "d-flex flex-column align-center" }, [
          _createElementVNode("h1", { class: "my-6" }, "Art classes"),
          _createElementVNode("div", { class: "mb-6 d-flex flex-column align-center" }, [
            _createElementVNode("p", { class: "mb-0" }, " Visit the LoLA studio where your child can be freely creative in a warm and welcoming environment while you relax and unwind in our Cafe space. "),
            _createElementVNode("p", null, " Sign up to a term of art classes or pop in for a weekend one-off ")
          ])
        ], -1)
      ])),
      _: 1
    }),
    _createVNode(_component_SplitComponent, {
      imageSrc: './img/images/lola_homepage_kids_art_club_ages 4-7.png',
      title: 'Kids Art Club ages 4+ | ages 4 - 7 | ages 8 - 11',
      description: 'A joyful creative moment in your child’s week where they can learn about artists and art styles, get messy and explore new materials and have fun with art in a beautiful calming space! Each half term children look at a new theme, and each week they experiment with different art supplies and techniques.',
      infoList: [
        'Children will also have time to peruse our little art library to reinforce what they are learning in class.',
        'Dates and times: Term-time week days. Please see the schedule for age groups.',
        'Sessions run in terms from September to July and are charged every half term.',
      ],
      buttons: [
        {
          text: 'Register for Thurs (ages 4-7)',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_club_thurs_4_7`,
        },
        {
          text: 'Register for Wed (ages 4+)',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_club_wed_8_11`,
        },
        {
          text: 'Register for Thurs (ages 8-11)',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_club_thurs_8_11`,
        },
      ]
    }, null, 8, ["buttons"]),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: './img/images/lola_homepage_little_ones.png',
      title: 'Little Ones | ages 2 - 4',
      description: 'This class is all about the process and experimenting! LoLA for Little Ones is designed to help young children be creative at an early age. Each class explores new textures, styles and materials. The activities are focused on the process and the experience for the child, rather than on the end result itself.',
      infoList: [
        'We kindly ask that you stay and co-create with your little ones. These sessions are designed for 2 to 4 year olds.',
        'Dates and times: Term-time week days. Sessions run in terms from September to July and are charged every half term.',
      ],
      buttons: [
        {
          text: 'Register for Tuesday',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_lo_tues`,
        },
        {
          text: 'Register for Saturday',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_sat_12_30`,
        },
        {
          text: 'Register for Friday',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_lo_fri`,
        },
      ],
      reverseLayout: true
    }, null, 8, ["buttons"]),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: './img/images/lola_homepage_open_studio.png',
      title: 'Open Studio | All ages',
      description: 'At the LoLA Studio there is always something to do! Whilst teaching workshops are not being held, come along to the cafe for a coffee whilst your child gets freely creative!',
      infoList: [
        'Open Studios are an invitation to come to the table and make/play with the open-ended projects provided. ',
        'Although there will be an adult present, this is not a set teaching session. We kindly ask that you stay within the LoLA Cafe and help your child if they need assistance. All materials will be provided, and aprons available, but please come in clothes they don’t mind painting in!',
        'Open studio tickets include a hot drink for an adult and a space for a child at the workshop table. The spaces are on a first come, first serve basis and are available to buy at the till and online. The sessions last for maximum one hour.',
      ],
      buttons: [
        {
          text: 'Click on calendar to register',
          color: '#D8B061',
          type: 'click',
        },
      ]
    }),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      reverseLayout: true,
      imageSrc: './img/images/lola_homepage_home_ed.png',
      title: 'Home Education | ages 4-7 | ages 8-11',
      description: 'The Home Education sessions aim to support home educators with their art curriculum. Following the same themes as the other art clubs at the studio, children will learn about artists and art styles and explore new materials, while having fun with art!',
      infoList: [
        'Dates and times: Term-time week days. Please see the schedule for age groups. Sessions run in terms from September to July and are charged every half term.',
      ],
      buttons: [
        {
          text: 'Register for Thur (ages 4-7)',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_hed_4_7`,
        },
        {
          text: 'Register for Thur (ages 8-11)',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_hed_8_11`,
        },
      ]
    }, null, 8, ["buttons"]),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: '/img/images/lola_homepage_young_adults.png',
      title: 'Young Adults Creative Club | Ages 12+',
      description: 'A joyful creative moment in your Teen or pre-Teens’s week where they can relax and unwind and let their creative juices flow in a beautiful environment! There is no pressure or exam preparation in this group, simply a space where young people can come and explore new materials and be inspired by artists and art styles. ',
      infoList: [
        'Each half term we will look at a new theme, and experiment with different art supplies and techniques each week',
        'Dates and times: Term-time week days. Sessions run in terms from September to July and are charged every half term.',
      ],
      buttons: [
        {
          text: 'Contact us to enquire on availability',
          color: '#D8B061',
          type: 'click',
        },
      ]
    }),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      reverseLayout: true,
      imageSrc: '/img/images/lola_homepage_weekend.jpg',
      title: 'Weekend Workshops | Themed | Ages 5+',
      description: 'These weekend workshops are one-off special classes where children get to go in depth on specific themes, or work in particular materials. This can range from Frida Kahlo-inspired canvas paintings to animal clay pots, from African mask-making to Giacometti inspired wire sculptures!',
      infoList: [
        'These sessions are on a first come first served basis. Please sign up and book online to secure a place in advance.',
      ],
      buttons: [
        {
          text: 'Register for Sat Morning',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_sat_10_30`,
        },
        {
          text: 'Register for Sat Afternoon',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_sat_1_30`,
        },
      ]
    }, null, 8, ["buttons"]),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: '/img/images/lola_homepage_family_workshops.jpg',
      title: 'Family Workshops | Ages 5+',
      description: 'What is more fun than being creative together?! These weekend workshops offer a perfect time to make art with loved ones, creating something together and spending screen-free quality one-on-one time with your children.',
      infoList: [
        'These sessions are on a first come first served basis. Please sign up and book online to secure a place in advance.',
      ],
      buttons: [
        {
          text: 'Register for Sunday',
          color: '#D8B061',
          link: `event-details/${_ctx.term.abb}02_fam_sun`,
        },
      ]
    }, null, 8, ["buttons"]),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: '/img/images/lola_homepage_holidays.jpeg',
      title: 'Holiday Workshops at LoLA',
      description: 'During school holidays LoLA offers a fabulous host of creative workshops for all ages! From Little Ones (ages 2-4) groups to fun art workshops for children ages 5+.',
      infoList: [
        'When a taught workshop is not being held, the LoLA Open Studio continues throughout the holidays, where the art table has projects and inspiration for your children to freely create while you relax and have a coffee in the Cafe!',
      ],
      buttons: [
        {
          text: 'See Holiday Schedule',
          color: '#D8B061',
          link: `holiday-workshops`,
        },
      ]
    }),
    _createVNode(_component_ResponsiveImageGridComponent),
    _createVNode(_component_SplitComponent, {
      imageSrc: '/img/images/lola_homepage_adult.jpeg',
      title: 'Adult Art Evenings',
      description: 'These creative evenings are a fantastic chance to get freely arty with friends! From colourful canvas painting to experimenting with printmaking techniques, these evenings offer the chance to relax and unwind in a beautiful space and allow your creative juices to flow!',
      infoList: [
        'Each adult class is focused on one open-ended project, starting at 7pm and finishing at 8.30pm',
        'Tickets include all art materials, a delicious cocktail and nibbles! ',
      ],
      buttons: [
        {
          text: 'See Adult Workshops Schedule',
          color: '#D8B061',
          link: `adult-art-workshops`,
        },
      ]
    }),
    _createVNode(_component_FullWidthBannerComponent, {
      class: "mt-10",
      imageSrc: './img/images/homepage.png'
    }),
    _createVNode(_component_ResponsiveImageGridComponent)
  ]))
}